import { render, staticRenderFns } from "./UiMediaUploader.vue?vue&type=template&id=28cefddc&scoped=true&"
import script from "./UiMediaUploader.vue?vue&type=script&lang=js&"
export * from "./UiMediaUploader.vue?vue&type=script&lang=js&"
import style0 from "./UiMediaUploader.vue?vue&type=style&index=0&id=28cefddc&prod&lang=scss&"
import style1 from "./UiMediaUploader.vue?vue&type=style&index=1&id=28cefddc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28cefddc",
  null
  
)

export default component.exports